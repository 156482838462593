import clsx from "clsx";

import Target from "@components/Target";

const CategoryTokens = ({ categories }) => {
	return (
		<div className={clsx("CategoryTokens", "flex flex-wrap")}>
			{categories.map((category) => {
				return (
					<Target
						key={category.id}
						target={{ element: category, type: "entry" }}
						className={clsx(
							"CategoryTokens__token",
							"flex text-xs px-2 py-1 rounded-sm",
							"mr-1 last-of-type:mr-0 mb-1",
							"whitespace-nowrap",
							"bg-purple-01",
							"text-white-01",
							"hover:bg-black-01",
							"transition-colors",
							{
								"bg-green-05 text-black-01 hover:text-white-01":
									category.groupHandle === "topics",
							},
							{
								"bg-purple-warm-04 text-white-01":
									category.groupHandle === "resourceTypes" ||
									category.groupHandle === "newsTypes",
							},
							{
								"bg-beige-02 text-black-01 hover:text-white-01":
									category.groupHandle === "industries",
							},
							{
								"bg-purple-01 text-white-01":
									category.groupHandle === "blogCategories",
							}
						)}
					>
						{category.title}
					</Target>
				);
			})}
		</div>
	);
};

export default CategoryTokens;
